.toolbar {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
}

.toolbar-button {
    background-color: white;
    color: #616161;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    background: none;
}

.datepicker-input {
    border: none;
    outline: none;
    font-size: 24px;
    text-align: center;
    cursor: pointer;
    font-family: "'Raleway', sans-serif";
    color: #4A6EAC;
    font-weight: 700;
    width: 200px;
    background: none;
}

.datepicker {
    z-index: 9999;
    position: relative;
}

.calendar-container {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.rbc-header {
    color: "#616161";
    font-weight: 900;
    font-size: "24px";
    height: 100px;
    display: flex;
    text-align: center;
}

.rbc-header span {
    font-size: "54px";

}

.rbc-label {
    display: none;
}

.rbc-row {
    font-family: "'Raleway', sans-serif";
    border-bottom: 1px solid #ddd;

}

.rbc-timeslot-group {
    min-height: 100px;
}

.rbc-event {
    border: none;
    height: unset !important;
}

.rbc-day-slot .rbc-time-slot {
    border: none;
}

.rbc-day-slot .rbc-event {
    border: none;
}

.rbc-time-view .rbc-row {
    min-height: unset !important;
}

.header {
    font-family: 'Raleway', sans-serif;
    color: #616161;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.header-date {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

.header-day {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
}

.rbc-button-link {
    width: 100%;
}

.rbc-event-label {
    display: none;
    background-color: white;
}

.survey-event {
    background-color: #EBEBEB;
    font-family: 'Raleway';
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    color: #424242;
    height: 34px;
    display: flex;
}

.single-event {
    padding: 5px;
}

.double-event {
    background-color: #EBEBEB;
    font-family: 'Raleway';
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    color: #424242;
    height: 90px;
    display: flex;
    flex-direction: column;
    position: relative;
    border-bottom: 1px solid #ddd;
}

.full_event {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.rbc-row-content {
    z-index: 0;
}

.hidden {
    display: none;
}

.rbc-calendar {
    height: unset !important;
}

.react-datepicker__day--selected {
    border-radius: 50%;
}

.react-datepicker__week--selected,
.react-datepicker__week:has([aria-selected=true]) {
    background-color: #E8EBFA;
}

.rbc-calendar,
.rbc-time-view {
    border-bottom: none !important;
}

.rbc-calendar:not(:first-child) {
    border-top: none !important;
}

.rbc-row.rbc-time-header-cell:has(.hidden) {
    display: none;
}

.rbc-day-bg {
    border-right: 1px solid #ddd !important;
}