.cedreactour-close-button {
  color: var(--rt-close-btn);
}

.cedreactour__close-button:hover {
  color: var(--rt-close-btn-disabled);
}

.cedreactour__close-button svg {
  display: block;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}